import _charm from "charm";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;

/**
 * Pace
 *
 * A progress bar for the command-line.
 *
 * Example usage:
 *
 *     var total = 50000,
 *         count = 0,
 *         pace = require('pace')(total);
 *
 *     while (count++ < total) {
 *       pace.op();
 *
 *       // Cause some work to be done.
 *       for (var i = 0; i < 1000000; i++) {
 *         count = count;
 *       }
 *     }
 */
// Module dependencies.
var charm = _charm;
/**
 * Pace 'class'.
 */

function Pace(options) {
  options = options || {}; // Total number of items to process.

  if (!options.total) {
    throw new Error("You MUST specify the total number of operations that will be processed.");
  }

  (this || _global).total = options.total; // Current item number.

  (this || _global).current = 0; // Maximum percent of total time the progressbar is allowed to take during processing.
  // Defaults to 0.5%

  (this || _global).max_burden = options.maxBurden || 0.5; // Whether to show current burden %.

  (this || _global).show_burden = options.showBurden || false; // Internal time tracking properties.

  (this || _global).started = false;
  (this || _global).size = 50;
  (this || _global).inner_time = 0;
  (this || _global).outer_time = 0;
  (this || _global).elapsed = 0;
  (this || _global).time_start = 0;
  (this || _global).time_end = 0;
  (this || _global).time_left = 0;
  (this || _global).time_burden = 0;
  (this || _global).skip_steps = 0;
  (this || _global).skipped = 0;
  (this || _global).aborted = false; // Setup charm.

  (this || _global).charm = charm();

  (this || _global).charm.pipe(process.stdout); // Prepare the output.


  (this || _global).charm.write("\n\n\n");
}
/**
 * Export a factory function for new pace instances.
 */


exports = function (options) {
  if (typeof options === "number") {
    options = {
      total: options
    };
  }

  return new Pace(options);
};
/**
 * An operation has been emitted.
 */


Pace.prototype.op = function op(count) {
  if (count) {
    (this || _global).current = count;
  } else {
    (this || _global).current++;
  }

  if (this.burdenReached()) {
    return;
  } // Record the start time of the whole task.


  if (!(this || _global).started) {
    (this || _global).started = new Date().getTime();
  } // Record start time.


  (this || _global).time_start = new Date().getTime();
  this.updateTimes();
  this.clear();
  this.outputProgress();
  this.outputStats();
  this.outputTimes(); // The task is complete.

  if ((this || _global).current >= (this || _global).total) {
    this.finished();
  } // Record end time.


  (this || _global).time_end = new Date().getTime();
  (this || _global).inner_time = (this || _global).time_end - (this || _global).time_start;
};
/**
 * Update times.
 */


Pace.prototype.updateTimes = function updateTimes() {
  (this || _global).elapsed = (this || _global).time_start - (this || _global).started;

  if ((this || _global).time_end > 0) {
    (this || _global).outer_time = (this || _global).time_start - (this || _global).time_end;
  }

  if ((this || _global).inner_time > 0 && (this || _global).outer_time > 0) {
    // Set Current Burden
    (this || _global).time_burden = (this || _global).inner_time / ((this || _global).inner_time + (this || _global).outer_time) * 100; // Estimate time left.

    (this || _global).time_left = (this || _global).elapsed / (this || _global).current * ((this || _global).total - (this || _global).current);
    if ((this || _global).time_left < 0) (this || _global).time_left = 0;
  } // If our "burden" is too high, increase the skip steps.


  if ((this || _global).time_burden > (this || _global).max_burden && (this || _global).skip_steps < (this || _global).total / (this || _global).size) {
    (this || _global).skip_steps = Math.floor(++(this || _global).skip_steps * 1.3);
  }
};
/**
 * Move the cursor back to the beginning and clear old output.
 */


Pace.prototype.clear = function clear() {
  (this || _global).charm.erase("line").up(1).erase("line").up(1).erase("line").write("\r");
};
/**
 * Output the progress bar.
 */


Pace.prototype.outputProgress = function outputProgress() {
  (this || _global).charm.write("Processing: ");

  (this || _global).charm.foreground("green").background("green");

  for (var i = 0; i < (this || _global).current / (this || _global).total * (this || _global).size - 1; i++) {
    (this || _global).charm.write(" ");
  }

  (this || _global).charm.foreground("white").background("white");

  while (i < (this || _global).size - 1) {
    (this || _global).charm.write(" ");

    i++;
  }

  (this || _global).charm.display("reset").down(1).left(100);
};
/**
 * Output numerical progress stats.
 */


Pace.prototype.outputStats = function outputStats() {
  (this || _global).perc = (this || _global).current / (this || _global).total * 100;
  (this || _global).perc = padLeft((this || _global).perc.toFixed(2), 2);

  (this || _global).charm.write("            ").display("bright").write((this || _global).perc + "%").display("reset");

  (this || _global).total_len = formatNumber((this || _global).total).length;

  (this || _global).charm.write("   ").display("bright").write(padLeft(formatNumber((this || _global).current), (this || _global).total_len)).display("reset");

  (this || _global).charm.write("/" + formatNumber((this || _global).total)); // Output burden.


  if ((this || _global).show_burden) {
    (this || _global).charm.write("    ").display("bright").write("Burden: ").display("reset");

    (this || _global).charm.write((this || _global).time_burden.toFixed(2) + "% / " + (this || _global).skip_steps);
  }

  (this || _global).charm.display("reset").down(1).left(100);
};
/**
 * Output times.
 */


Pace.prototype.outputTimes = function outputTimes() {
  // Output times.
  var hours = Math.floor((this || _global).elapsed / (1000 * 60 * 60));
  var min = Math.floor((this || _global).elapsed / 1000 % (60 * 60) / 60);
  var sec = Math.floor((this || _global).elapsed / 1000 % 60);

  (this || _global).charm.write("            ").display("bright").write("Elapsed: ").display("reset");

  (this || _global).charm.write(hours + "h " + min + "m " + sec + "s");

  if ((this || _global).time_left) {
    hours = Math.floor((this || _global).time_left / (1000 * 60 * 60));
    min = Math.floor((this || _global).time_left / 1000 % (60 * 60) / 60);
    sec = Math.ceil((this || _global).time_left / 1000 % 60);

    (this || _global).charm.write("   ").display("bright").write("Remaining: ").display("reset");

    (this || _global).charm.write(hours + "h " + min + "m " + sec + "s");
  }
};
/**
 * The progress has finished.
 */


Pace.prototype.finished = function finished() {
  (this || _global).charm.write("\n\n");

  (this || _global).charm.write("Finished!");

  (this || _global).charm.write("\n\n");
};
/**
 * Check if the burden threshold has been reached.
 */


Pace.prototype.burdenReached = function burdenReached() {
  // Skip this cycle if the burden has determined we should.
  if ((this || _global).skip_steps > 0 && (this || _global).current < (this || _global).total) {
    if ((this || _global).skipped < (this || _global).skip_steps) {
      (this || _global).skipped++;
      return true;
    } else {
      (this || _global).skipped = 0;
    }
  }

  return false;
};
/**
 * Utility functions.
 */
// Left-pad a string.


function padLeft(str, length, pad) {
  pad = pad || " ";

  while (str.length < length) str = pad + str;

  return str;
} // Ported from php.js. Same has php's number_format().


function formatNumber(number, decimals, dec_point, thousands_sep) {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");

  var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec);
    return "" + Math.round(n * k) / k;
  }; // Fix for IE parseFloat(0.55).toFixed(0) = 0;


  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }

  return s.join(dec);
}

export default exports;